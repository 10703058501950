import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import LoadingScreen from '../../mainSectionComponents/loading/LoadingScreen';
import { getProductValiationList, ProvisionRatio } from '../../utils/getData/ProductValiationList';

export const RatioButton: React.FC<{ teamColor: string, product_code: string }> = ({ teamColor, product_code }) => {
  // react accdion
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(true);

  const [provisionRatio, setProvisionRatio] = useState<ProvisionRatio | null>(null);

  useEffect(() => {
    const fetchProvisionRatioData = async () => {
      try {
        setLoading(true);
        const response = await getProductValiationList(product_code);
        setProvisionRatio(response);
      } catch (error) {

      } finally {
        setLoading(false);
      }
    };
    fetchProvisionRatioData();
  }, []);

  const renderProductVariations = () => {
    if (loading) {
      return <LoadingScreen />;
    }
    if (provisionRatio === null) {
      return null;
    }
    // 商品がなにもない場合
    if (!provisionRatio.product) {
      return null;
    }

    const variations = provisionRatio.product.product_variations;
    return Object.keys(variations).map((key, index) => {
      const { lottery_info, products } = variations[key];
      const lotteryPercent = (lottery_info / 100).toFixed(2) + '%';
      return (
        <>
          <Accordion.Item eventKey={index.toString()} key={key}>
            <Accordion.Header>{key}</Accordion.Header>
            <Accordion.Body className='d-flex flex-wrap px-reset'>
              <div className="m-1">{key} 排出率：{lotteryPercent}</div>
              <p className="m-1">※同レアリティのカードは全て均等割合で提供しています。</p>
              {products.map(product => {
                const imgSrc = `/image/${provisionRatio.product.team_name}/${product_code}/${String(product.id).padStart(8, '0')}.jpg`;
                return (
                  <div className="w-50 text-center fs-caption mb-1" key={product.id}>
                    <img className={`w-80 ${product.is_stock ? '' : 'grayscale-100'}`} src={imgSrc} alt={product.name} />
                    <div className='mb-2'>
                      {product.name}
                      {product.is_stock ? <></> : <div className='text-danger'>排出停止中</div>}
                    </div>
                  </div>
                );
              })}
            </Accordion.Body>
          </Accordion.Item>
        </>
      );
    });
  };

  return (
    <>
      <Button variant="secondary" onClick={handleShow} className={`bg-${teamColor}-secondary btn w-75 mx-auto m-2`}>
        提供割合
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>提供割合</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion>
            <Accordion.Item eventKey="99">
              <Accordion.Header>商品抽選方法と商品販売終了のタイミングについて</Accordion.Header>
              <Accordion.Body>
                <p className='mb-0'>
                  商品の抽選は1プレイ毎に提供割合の表示確率にて商品を抽選しています。<br />
                  そのため、特定のレアリティ(N,SR等)が連続して排出される場合があります。<br />
                  商品毎の各レアリティ(N,SR等)の内、いずれかのレアリティの在庫がなくなった場合、<br />
                  それ以降に提供割合通りの抽選が行えないため、商品販売終了となります。
                </p>
              </Accordion.Body>
            </Accordion.Item>
            {renderProductVariations()}
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <button type="button" className="btn btn-secondary w-250 mx-auto my-2 py-1" >オンサイトカードの提供割合</button> */}
    </>

  );
}

export default RatioButton;